import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FaArrowAltCircleDown } from "react-icons/fa"
const about = () => {
  return (
    <Layout>
      <Seo
        title="Experts in building digital solutions to help business succeed"
        description="Based in Delaware, we specialized in Web Design and Development, Mobile App Development, E-commerce web design, Search Engine Optimization, Branding, & UI/UX."
        keyword="About Us"
      />
      <Container fluid className="position-relative min-vh-100">
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col
            md={6}
            sm={12}
            className="align-items-center text-center my-auto"
          >
            <h1 className="text3d fs-3 mb-5">ABOUT US</h1>
          </Col>
          <Col md={6} sm={12}>
            <StaticImage
              alt="About Us Poster"
              src="../images/aboutus.png"
              className="me-1 pe-1"
              placeholder="tracedSVG"
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row className="text-center text-white">
          <Col>
            <blockquote className="blockquote text-center">
              <h5 className="h5 luminance">
                We must learn what customers really want, not what they say they
                want or what we think they should want.
              </h5>
              <footer className="blockquote-footer mt-2 text-right">
                Eric Ries in
                <cite title="Source Title">"The Lean Startup"</cite>
              </footer>
            </blockquote>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <div className="position-absolute bottom-0 start-50 text-white mb-1">
          <FaArrowAltCircleDown className="bouncearrow" />
        </div>
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <br />
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center">
          <p
            className="bg-text text-wrap"
            data-bg-text="ABOUT&nbsp;US"
            data-aos="zoom-in-right"
          >
            <br />
            Having worked across companies for 19 years, at the age of 38, I was
            inspired to start my own company specializing in development,
            designing and promotion of websites, the result of which is the
            Softcode. Based out of Delaware, TheSoftCode LLC aims for the ultimate
            customer satisfaction as the end goal and has been working
            constantly to achieve the same. Apart from my own experience in the
            industry, I also have a team of professionals who have profound
            knowledge and experience in every aspect of online web designing,
            development and data management.
          </p>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          WHO ARE WE
        </h2>
        <br />
        <br />
        <Container className="text-white text-wrap text-center fs-5">
          Our main team comprises certified professionals and are skilled and
          certified in Business Analysis, theme building with Wordpress, Drupal,
          etc. and also customized themes where required, HTML designing, .NET,
          Java and PHP programming, Search Engine Analysis, App developing and
          much more. Our team has everything it takes to handle a website launch
          to tracking the website performance, managing content and data
          warehousing. Each of us can function alone as well as in a team and
          deliver what the client requires within the agreed timelines and as
          per the agreed quality. The projects that we have delivered so far can
          testify on the kind of professionalism and quality you can expect from
          the team at Softcode.
          <br />
          <br />
          Our combined experience and our knowledge serve as our strength as we
          work on multiple projects across different industries. We never shy
          away from learning and keep ourselves updated on the latest technology
          to ensure that we only deliver the right solution at the right time.
          The knowledge and the expertise with which we have handled our clients
          have helped us to gain a formidable reputation in the short time we
          have come into existence.
        </Container>
        <br />
        <br />
        <br />
      </Container>
      <Container fluid className="bg-white">
        <br />
        <Container className="text-center">
          <br />
          <h4 className="bg-textdark" data-aos="flip-up">
            AFFILIATIONS
          </h4>
          <br />
          <br />
          <Row className="row-col-2 ms-auto me-auto w-50">
          <Col>
          <a
            href="https://clutch.co/profile/thesoftcode"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              alt="Best Web Design Company"
              src="../images/clutch-vector-logo.png"
              placeholder="tracedSVG"
              layout="constrained"
              width={150}
              formats={["auto", "webp", "avif"]}
            />
          </a></Col>
          <Col>
          <a
            href="https://www.designrush.com/agency/profile/thesoftcode-llc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              alt="Best Web Design Company"
              src="../images/Design-Rush-Badge2.png"
              placeholder="tracedSVG"
              layout="constrained"
              width={150}
              formats={["auto", "webp", "avif"]}
            />
          </a></Col>
          </Row>
          <br />
        </Container>
        <br />
        <br />
      </Container>
    </Layout>
  )
}

export default about
